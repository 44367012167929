import React from 'react';
// Make sure this path matches your project structure
import '../style/services.css';


const Services = () => {
  return (
    <section id="services">
      <h1>Amazing and Fast Services</h1>
      <p>
        For an eCommerce logistics company, Nihal Air Cargo in the forward direction involves receiving an order, 
        arranging for the item, packaging, preparing its invoice, dispatching, and delivering the item to the 
        customer's doorstep. The time between receiving the order and its distribution depends on the availability 
        of the material and the location of the consignee. For specific locations, a separate delivery charge could be applicable. 
        From the time of dispatch until delivery of a consignment, it is the responsibility of the seller to notify 
        the exact location of a shipment to its respective consignee through tracking WhatsApp SMS or CALL notifications. 
        We provide services to multiple metropolitan cities with different types of shipments in a short period of time. 
        Our transportation hub is the heartbeat of our logistics network. Here, various modes of transportation converge, 
        including trucks, trains, and potentially air and sea connections. This central hub facilitates the smooth 
        transfer of cargo between different transportation modes.
      </p>
      
      <div className="service">
        <div>
          <h2>By Flight</h2>
       
          <img
          src="https://media.istockphoto.com/id/1526986072/photo/airplane-flying-over-tropical-sea-at-sunset.jpg?s=612x612&w=0&k=20&c=Ccvg3BqlqsWTT0Mt0CvHlbwCuRjPAIWaCLMKSl3PCks="
          alt="Flight"
        />
          <p>
            We redefine special services through air freight logistics with a commitment to speed, reliability, and efficiency. 
            Our comprehensive range of services is designed to meet the dynamic demands of global trade and deliver your cargo 
            to its destination with precision. Explore the heights of excellence in air freight logistics with Nihal Air Cargo at the lowest price.
          </p>
          <p>
            We understand the critical importance of air freight in the global logistics landscape. Our commitment to excellence, 
            coupled with a dedication to meeting the unique needs of our clients, makes us your trusted partner for seamless 
            and reliable air freight logistics. Experience the power of elevated logistics services with Nihal Air Cargo.
          </p>
        </div>
        
        <div>
          <h2>By Train</h2>
    
          <img
          src="https://cdn.siasat.com/wp-content/uploads/2020/04/trains.jpg"
        
          
          alt="Train"
        />
          <p>
            We elevate your logistics experience with efficient and sustainable rail freight solutions. Our comprehensive range 
            of services is designed to provide cost-effective and reliable transportation options for your cargo. Explore the 
            advantages of rail freight logistics with us. Enhance your domestic logistics strategy with our reliable and 
            punctual domestic rail freight services.
          </p>
          <p>
            We offer cost-effective transportation solutions for your cargo, connecting key locations within your country 
            efficiently. We pride ourselves on offering efficient, cost-effective, and environmentally friendly rail freight 
            logistics solutions. Our dedicated team is committed to providing tailored services that meet your unique 
            transportation needs. Experience the reliability and sustainability of rail freight logistics with Nihal Air Cargo.
          </p>
        </div>
        
        <div>
          <h2>By Vehicles</h2>
          
          <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-ueKtZETrsXlMKNOST_jUG-xIvk8LpZFgkw&s"
          alt="Truck"
        />
          <p>
            We drive efficiency, reliability, and flexibility into your logistics strategy. Our truck freight logistics services 
            are designed to meet the diverse needs of your supply chain, offering seamless transportation solutions for your cargo. 
            Explore the road to success with Nihal Air Cargo. When time is of the essence, our express trucking services deliver 
            on speed and reliability. We prioritize quick turnaround times for urgent shipments, ensuring your goods reach their 
            destination swiftly and securely.
          </p>
          <p>
            We understand that the road to success is paved with efficiency and reliability. Our truck freight logistics services 
            are crafted to meet the dynamic demands of today's supply chains, ensuring your cargo reaches its destination seamlessly. 
            Partner with Nihal Air Cargo and experience the driving force behind effective logistics solutions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
