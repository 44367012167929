import React from "react";
import "../style/home.css"; // Import the custom CSS file
import Footer from "./Footer";
import { Link } from "react-router-dom";
import exampleImage from "../images/nihal.jfif"; // Adjust the path as necessary

const Home = () => {
  return (
    <>
      <section className="home-section">
      <h2 className="home-title">
      <span className="text-1">Enhance</span> 
      <span className="text-2">Your</span> 
      <span className="text-3">Future</span> 
      <span className="text-4">with</span> 
      <span className="text-5">Us,</span> 
      <span className="text-6">We</span> 
      <span className="text-7">are</span> 
      <span className="text-8">with</span> 
      <span className="text-9">you</span>
    </h2>
<div className="gstin">
<p className="home-description">Ecommerce Logistics Company</p>
<h2 className="gst">GSTIN: 07BFQPM9461M1ZI</h2>
</div>
       

    
        <div className="home-buttons">
          <Link to="/services" className="home-button">
            Learn More
          </Link>
          <a href="/contact" className="home-button">
            Contact Us
          </a>
        </div>
      </section>

      <section>
      <div className="cont">
      <div className="text-cont">
        <p className="para-desc">
          Nihal Air Cargo is more than just a logistics company; we are a trusted partner in your global supply chain. Whether it’s a small parcel or large-scale cargo, we deliver with precision, ensuring your goods arrive safely and on time.
        </p>
      </div>
      <div className="image-cont">
        <img src={exampleImage} alt="Example Image" className="cargo-image" />
      </div>
    </div>
      </section>

     
      

      <section>
        <div>
          <h3>Multi City Transporters</h3>
          <p>
          Nihal Air Cargo is a trusted leader in national logistics, offering seamless air freight services across multiple cities and countries. Through our specialized Multi City Transporters network, we ensure swift and reliable delivery of cargo between major urban centers. Whether it's local or international shipping, we provide tailored solutions that meet the unique needs of businesses and individuals.
          </p>
        </div>

        <div>
          <h3>Get On Time</h3>
          <p>
          Timely delivery is our top priority. With our efficient logistics network and advanced air freight solutions, we ensure your cargo reaches its destination on schedule. Whether it's domestic or international, our team is dedicated to offering reliable and punctual service every time. You can trust us to manage time-sensitive shipments, providing peace of mind that your goods will always get to where they need to be, right on time.
          </p>
        </div>
        <div>
          <h3>24/7 Helpline</h3>
          <p>
          We understand that logistics never sleep. That’s why our 24/7 Helpline is always available to assist you with any inquiries or support you may need, no matter the time or place. Whether you have questions about shipment status, customs processes, or any other service, our dedicated team is ready to provide prompt and reliable assistance around the clock, ensuring your cargo journey remains smooth and worry-free. We’re here for you, anytime you need us. +91-9136634206 call us 24/7 365 days a year our operators are
            always on standby to assist.
          </p>
        </div>
      </section>
      <section>
        <h3>OUR GROWING NETWORK</h3>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1RyCqVCpD2wke75QFEjkwR1C6jIGW6NE3iw&s"
          alt="My Image"
        />
        <p>
          Celcius connects shippers & transporters pan India & our network grows
          with each passing day. We carry & store any perishable cargo & we
          understand how to love for our consignments.
        </p>
      </section>
      <section>
        <div>
          <h3>Why Choose Us?</h3>
          <p>Experience unparalleled reliability and expertise.</p>
          <p>
            Timely delivery is our hallmark, ensuring your goods stay on track.
          </p>
          <p>
            Our dedicated team is always accessible, providing personalized
            support and guidance.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
