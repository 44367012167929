import React from 'react';
import '../style/support.css'; // Importing the CSS file

const Support = () => {
  return (
    <div className="support-container">
      <h1 className="support-title">Support Center</h1>
      
      {/* Contact Information Section */}
      <section className="support-contact">
        <h2>Contact Us</h2>
        <p>If you need help or have any questions, feel free to reach out to us:</p>
        <ul>
          <li><strong>Email:</strong> nihalaircargo@gmail.com</li>
          <li><strong>Phone:</strong> +91-9136634206</li>
          <li><strong>Office Address:</strong>
          masjid Bunder West, Carnac Bridge Mumbai, India</li>
        </ul>
      </section>

      {/* FAQs Section */}
      <section className="support-faq">
        <h2>Frequently Asked Questions (FAQs)</h2>
        <div className="faq-item">
          <h3>How can I track my shipment?</h3>
          <p>You can track your shipment by entering your tracking number on our tracking page.</p>
        </div>
        <div className="faq-item">
          <h3>What are the shipping options available?</h3>
          <p>We offer standard, expedited, and international shipping options for all types of goods.</p>
        </div>
        <div className="faq-item">
          <h3>How can I update my shipping information?</h3>
          <p>You can update your shipping details by contacting our support team through email or phone.</p>
        </div>
      </section>

     
    </div>
  );
};

export default Support;
