import React from 'react';
import '../style/about.css'; // Importing custom CSS for styling

const About = () => {
  return (
    <div className="about-container">
      <section className="about-section">
        <h1 className="about-title">About Our Logistics Business</h1>
        
        {/* Company Overview */}
        <section className="about-overview">
          <h2>Who We Are</h2>
          <p>
            We are a leading logistics and transportation company with a global presence. Our commitment to excellence ensures that your goods reach their destination safely, on time, and in the most efficient manner possible. From small parcels to large freight shipments, we offer a wide range of logistics solutions tailored to meet the needs of individuals and businesses alike.
          </p>
        </section>

        {/* Services */}
        <section className="about-services">
          <h2>Our Services</h2>
          <p>
            Our services include:
            <ul>
              <li>Freight Transportation</li>
              <li>Warehousing and Distribution</li>
              <li>Supply Chain Management</li>
              <li>Custom Logistics Solutions</li>
              <li>Parcel Delivery</li>
              <li>International Shipping</li>
            </ul>
          </p>
        </section>

        {/* Vision and Mission */}
        <section className="about-mission">
          <h2>Our Vision & Mission</h2>
          <p>
            Our mission is to transform logistics and transportation into a seamless, efficient, and reliable service. We aim to lead the industry in innovation and sustainability, constantly evolving to meet the challenges of a dynamic global economy. Our vision is to be the trusted partner of choice for businesses and individuals looking for top-quality logistics solutions.
          </p>
        </section>

        {/* Core Values */}
        <section className="about-values">
          <h2>Our Core Values</h2>
          <p>
            Integrity, innovation, and customer satisfaction are the values that guide our company. We believe in delivering on our promises and continuously pushing the boundaries to ensure that our services are at the forefront of the logistics industry.
          </p>
        </section>

        {/* Global Network */}
        <section className="about-global">
          <h2>Our Global Network</h2>
          <p>
            With offices in over 8 cities and a partner network that spans the globe, we are able to offer services in even the most remote locations. Our advanced tracking system ensures that our customers are always informed about the status of their shipments, giving them peace of mind.
          </p>
        </section>

        {/* Why Choose Us */}
        <section className="about-choose">
          <h2>Why Choose Us?</h2>
          <p>
            <ul>
              <li>Unmatched experience in handling complex logistics</li>
              <li>A dedicated team of professionals</li>
              <li>Customized solutions for every business need</li>
              <li>State-of-the-art technology and equipment</li>
              <li>24/7 customer support</li>
            </ul>
          </p>
        </section>

        {/* Conclusion */}
        <section className="about-conclusion">
          <h2>Looking Forward</h2>
          <p>
            At our logistics company, we’re more than just a transportation provider. We’re a strategic partner in your supply chain, ensuring that your goods move smoothly from point A to point B. Our commitment to innovation, reliability, and customer satisfaction sets us apart from the competition. Whether you're shipping locally or globally, we have the expertise to get the job done right.
          </p>
        </section>
      </section>
    </div>
  );
};

export default About;
