import React from "react";
import "../style/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <p className="footer-intro">
        Nihal Air Cargo: Delivering Your Cargo with Speed and Reliability
      </p>

      <div className="footer-col">
        <h3>Quick Links</h3>
        <h4>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </h4>
        <h4>
          <Link to="/contact">Contact Us</Link>
        </h4>
        <h4>
          <Link to="/support">Our Support</Link>
        </h4>
      </div>

      <div className="footer-col">
  <h3>Company Owner</h3>
  <h4>Esraful Momin</h4>
  <h4>We welcome all inquiries</h4>
  
  <div className="whatsapp-container">
    <a
      href="https://wa.me/9136634206"
      target="_blank"
      rel="noopener noreferrer"
      className="link"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="icon"
      />
      <span className="phoneNumber">9136634206</span>
    </a>
  </div>
</div>



      <div className="footer-col">
        <h3>Website Developer</h3>
        <h4>Aqueeq Azam</h4>
        <h4>Full Stack Developer & AI Engineer</h4>
        <a href="tel:8072906182">📞 8072906182</a>
      </div>
    </footer>
  );
}

export default Footer;


