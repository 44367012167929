import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './pages/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Branches from './pages/Branches';
import Contact from './pages/Contact';
import Admin from './pages/Admin';
import Login from './pages/Login';
import Privacy from './pages/Privacy';
import Support from './pages/Support';


const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/branches" element={<Branches />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />
       

      
      </Routes>
    </Router>
  );
};

export default App;
