// src/components/Admin.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db, auth } from '../config/Firebase';
import '../style/admin.css';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; 




const Admin = () => {
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false); // Track admin access
  const navigate = useNavigate(); // For redirecting

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check Firestore for the user's role
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          console.log('User Data:', userData); // Log the user data

          if (userData.role === 'admin') {
            setIsAdmin(true); // Set admin access
          } else {
            alert('Access Denied: Admins Only');
            navigate('/'); // Redirect to home if not admin
          }
        } else {
          console.error('User document does not exist.');
        }
      } else {
        alert('Please log in');
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  useEffect(() => {
    if (isAdmin) {
      // Fetch user data from Firestore only if admin
      const fetchUsers = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'users'));
          const userList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          console.log('Fetched Users:', userList); // Log fetched users
          setUsers(userList);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };

      fetchUsers();
    }
  }, [isAdmin]);

  useEffect(() => {
    console.log('isAdmin:', isAdmin); // Log isAdmin state
  }, [isAdmin]);

  return (
    <div className="admin">
      <h2>Admin Page - User List</h2>
      {users.length > 0 ? (
        <ul>
          {users.map((user) => (
            <li key={user.id} className="user-item">
              <strong>Name:</strong> {user.name} <br />
              <strong>Phone:</strong> {user.phone} <br />
              <strong>Address:</strong> {user.address} <br />
            </li>
          ))}
        </ul>
      ) : (
        <p>No users found.</p>
      )}
    </div>
  );
};

export default Admin;


