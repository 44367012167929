// Import the functions you need from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth } from "firebase/auth"; // Import Firebase Auth
// Optionally import analytics if needed
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA3kRXWpcsU6YgHCozweQVAea-54O2RP7g",
  authDomain: "nihal-air-cargo.firebaseapp.com",
  projectId: "nihal-air-cargo",
  storageBucket: "nihal-air-cargo.appspot.com",
  messagingSenderId: "998356668348",
  appId: "1:998356668348:web:0aad159cee8846fdf7e5b9",
  measurementId: "G-C33FT7E54H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore (for database operations)
const db = getFirestore(app);

// Initialize Firebase Authentication (for auth operations)
const auth = getAuth(app);

// Optionally initialize analytics if needed
// const analytics = getAnalytics(app);

export { db, auth };
