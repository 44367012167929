import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/Firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for the toasts
import '../style/contact.css'

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a new document with the form data
      await addDoc(collection(db, 'users'), {
        name: formData.name,
        phone: formData.phone,
        address: formData.address
      });

      // Success toast message
      toast.success('User data submitted successfully!', {
        position: toast('Message Sent ')
      });
      setFormData({ name: '', phone: '', address: '' }); // Clear form
    } catch (error) {
      // Error toast message
      toast.error('Error submitting data. Please try again.', {
        position: toast('Error')
      });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <div>
      <h2>User Information Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Address:</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Submit</button>
        <section>
          <h3>Or Contact me on WhatsApp</h3>
          <h5>
          <a
            href="https://wa.me/9136634206"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
               <h5>Esraful Momin</h5>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
              className="icon"
            />
           
            <span className="phoneNumber">9136634206</span>
          </a>
        </h5>
        <h5>
    
          <a
            href="https://wa.me/9136634206"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <h5>Gulam Momin</h5>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
              className="icon"
            />
          
            <span className="phoneNumber">8145831296</span>
          </a>
        </h5>
        </section>
      </form>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
};

export default Contact;
