import React from "react";
import "../style/branches.css";

const Branches = () => {
  return (
    <div>
      {/* Branches Section */}
      <section id="branches">
        <h1>Multiple Branches in many Cities</h1>
        <p>
          Our commitment to excellence extends across various branches, ensuring
          a comprehensive network to meet your logistics needs. Our
          strategically located branches play a vital role in providing
          efficient and seamless services. Explore the diverse branches of Nihal
          Air Cargo Logistics Services. Our regional operations centers serve as
          hubs for coordinating logistics activities within specific
          geographical regions. These centers streamline communication, manage
          inventory, and facilitate smooth transportation operations. In
          bustling urban environments, our distribution centers, strategically
          positioned in key cities, optimize last-mile delivery.
        </p>
        <p>
          These centers enhance the speed and efficiency of delivering goods to
          businesses and consumers in densely populated areas. Our network of
          branches is strategically designed to provide a seamless and efficient
          logistics experience. Each branch contributes to the overall success
          of our operations, ensuring that we meet and exceed the expectations
          of our clients in every aspect of their supply chain. Partner with
          Nihal Air Cargo for a comprehensive logistics solution that spans
          across our well-established branches.
        </p>

        <div className="serve">
          <div className="branch">
          <p style={{ fontSize: '32px' }}>Mumbai (Head Office)</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>

            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9136634206">📞 9136634206</a>

              <a href="tel:8145831296">📞 8145831296</a>

              <a href="tel:8145831244">📞 8145831244</a>

              <a href="tel:8766238126">📞 8766238126</a>
            </div>
            <p>
              <strong>Email Id:</strong>  <a href="mailto:nihalaircargo@gmail.com" target="_blank" rel="noopener noreferrer">
        nihalaircargo@gmail.com
      </a>
            </p>

            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong> , Godown No. 6, Mussafir Khana, Near
              Carnac Bridge, Saba Sidik Road, Mumbai, Maharashtra, 400001
            </p>
            <p>
              <strong>Branch Manager:</strong> Mr. Gulam Masum
            </p>
            <p>
              Mumbai is one of the top and oldest branches among all, and this
              is the head branch of Nihal Air Cargo, and most of the work is
              active from here. This is the location in Musafir Khana, Musjud
              Munder West. There are currently 10 employees, and all are market
              experts. Our working time in Mumbai is 12 hours, from 10 a.m. to
              10 p.m., and 6 business days except Sunday.
            </p>
          </div>
          <br />

          <div className="branch">
          
            <p style={{ fontSize: '32px' }}>Kolkata</p>

            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>

            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9733352579">📞 9733352579</a>

              <a href="tel:8240344325">📞 8240344325</a>

              <a href="tel:9330826765">📞 9330826765</a>

              <a href="tel:8766238126">📞 8766238126</a>

              <a href="tel:9136634206">📞 9136634206</a>
            </div>

            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong> 11 No. Kolu Tolla Street, Kolkata, West
              Bangal, 700073
            </p>
            <p>
              <strong>Branch Manager:</strong> Mr. Amimul Islam and Azad
            </p>
           
          </div>

          <div className="branch">
         
            <p style={{ fontSize: '32px' }}>Khidirpur, Kolkata</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>

            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9733352579">📞 9733352579</a>

              <a href="tel:8240344325">📞 8240344325</a>

              <a href="tel:7903401273">📞 7903401273</a>

              <a href="tel:8240344356">📞 8240344356</a>
            </div>

            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong> 5, Dr. Basu Road, Khidirpur, Kolkata,
              West Bangal, 700023
            </p>
            <p>
              <strong>Branch Manager:</strong> Mr. Anwar
            </p>
          
          </div>
          <br />
          <div className="branch">
         
            <p style={{ fontSize: '32px' }}>Chennai</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>
            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:8591808718">📞 8591808718</a>

              <a href="tel:9136634206">📞 9136634206</a>
              <br />
            </div>
            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong>
            </p>
            70/2, New No: 155, Swamy Nakek Street, Chaintandriprt, J.V.C.
            Network, Chennai - 600002.
            <p>
              <strong>Branch Manager:</strong> Sikandar Singh
            </p>
           
          </div>

          <br />
          <div className="branch">
         
            <p style={{ fontSize: '32px' }}>Delhi</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>
            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:8810495015">📞 8810495015</a>
              <a href="tel:9321293137">📞 9321293137</a>
              <a href="tel:9136634206">📞 9136634206</a>
              <br />
            </div>
            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong>
            </p>
            Ground Floor, Double, Story Motin Khan, Paharganj, Budh Vihar
            Mandir, New Delhi - 110055
            <p>
              <strong>Branch Manager:</strong> Kartik Rajput
            </p>
           
          </div>
          <br />

          <div className="branch">
          
            <p style={{ fontSize: '32px' }}>Bhiwandi (Mumbai)</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>
            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9733349813">📞 9733349813</a>
              <a href="tel:9136634206">📞 9136634206</a>
              <a href="tel:8759074126">📞 8759074126</a>

              <br />
            </div>
            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong>
            </p>
            Bhiwandi Mumbai - 110055
            <p>
              <strong>Branch Manager:</strong> Mr. Mubarak
            </p>
           
          </div>

          <div className="branch">
          
            <p style={{ fontSize: '32px' }}>Malda, Kalichak (West Bangale)</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>
            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9647080365">📞 9647080365</a>
              <a href="tel:9136634206">📞 9136634206</a>
              <br />
            </div>
            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong>
            </p>{" "}
            Kalichak, Malda, West Bangal - 732201
            <p>
              <strong>Branch Manager:</strong> Mr. Saydul Shaikh
            </p>
            
          </div>

          <div className="branch">
          
            <p style={{ fontSize: '32px' }}>Udhwa, Rajmahal, SHibganj (Jharkhand)</p>
            <p>
              <strong>Services Time:</strong>24/7{" "}
            </p>
            <p>
              <strong>Contact:</strong>{" "}
            </p>
            <div className="contanct">
              <a href="tel:9647080365">📞 9647080365</a>
              <a href="tel:9136634206">📞 9136634206</a>
              <br />
            </div>
            <p>
              <strong>Operating Hours:</strong> Monday to Saturday, 9 AM - 9 PM
            </p>
            <p>
              <strong>Address:</strong>
            </p>{" "}
            Udhwa, Rajmahal, Sahibganj - 816108
            <p>
              <strong>Branch Manager:</strong> Mr. Saydul Shaikh
            </p>
           
          </div>

          <br />
        </div>
      </section>
    </div>
  );
};

export default Branches;
