import React from 'react';
import '../style/privacy.css'; // Importing the CSS file

const Privacy = () => {
  return (
    <div className="privacy-container">
      <p>
        At Nihal Air Cargo, we are committed to protecting your privacy and ensuring the security of your personal information.
        This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our products, services, or website.
        By accessing or using any of our offerings, you consent to the practices described in this Privacy Policy.
      </p>
      <br />
      <p><b>Information We Collect:</b></p>
      <p>We collect various types of information, including but limited to:</p>
      <ul>
        <li><b>Personal Information:</b> This includes data such as your name, phone number, and other information you provide to contact us directly.</li>
        <li><b>Usage Information:</b> We may collect data about how you interact with our website, products, or services. This includes information such as your Address</li>
        <li><b>Cookies and Tracking Technologies:</b> We use cookies and similar technologies to enhance your user experience and gather information about your activities on our website.</li>
      </ul>
      <br />
      <p><b>How We Use Your Information:</b></p>
      <p>We use the collected information for various purposes, including:</p>
      <ul>
        <li>Providing and improving our products and services</li>
        <li>Personalizing your experience</li>
        <li>Communicating with you, including responding to inquiries and providing updates</li>
        <li>Analyzing and improving our website and marketing efforts</li>
        <li>Complying with legal obligations</li>
      </ul>
      <br />
      <p><b>Information Sharing:</b></p>
      <p>
        We do not sell, trade, or otherwise transfer your personal information to external parties without your consent, except as described in this Privacy Policy.
        We may share your information with trusted third parties, such as service providers, who assist us in operating our website, conducting business, or servicing you,
        provided they agree to keep this information confidential.
      </p>
      <br />
      <p><b>Security:</b></p>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
        However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>
      <br />
      <p><b>Your Choices:</b></p>
      <p>
        You have the right to control the personal information you provide to us. You can choose not to provide certain information,
        but this may limit your ability to use some features of our website or services. You may also manage your cookie preferences through your browser settings.
      </p>
      <br />
      <p><b>Children's Privacy:</b></p>
      <p>
        Our products, services, and website are not intended for individuals under the age of 13. We do not knowingly collect personal information from children.
        If you are a parent or guardian and believe your child has provided us with information, please contact us, and we will take steps to remove that information.
      </p>
      <br />
      <p><b>Changes to this Privacy Policy:</b></p>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The most recent version will be posted on our website with the effective date.
        Your continued use of our products, services, or website after any changes to the Privacy Policy indicates your acceptance of those changes.
      </p>
      <br />
      <p><b>Contact Us:</b></p>
      <p>
        If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at [Contact Information].
      </p>
      <br />
      <p>
        By using Nihal Air Cargo's products, services, or website, you acknowledge that you have read, understood, and agreed to the terms and conditions outlined in this Privacy Policy.
      </p>
    </div>
  );
};

export default Privacy;
