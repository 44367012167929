import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/navbar.css'; // Import the custom CSS file



const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="nav-title">
          <Link to="/">Nihal Air Cargo</Link>
        </div>
        {/* Mobile Menu Button */}
        <div className="mobile-menu-button" onClick={toggleMenu}>
          <button>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isOpen
                    ? 'M6 18L18 6M6 6l12 12'
                    : 'M4 6h16M4 12h16m-7 6h7'
                }
              ></path>
            </svg>
          </button>
        </div>

        {/* Navbar Links */}
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/branches" onClick={toggleMenu}>Branches</Link>
          <Link to="/services" onClick={toggleMenu}>Services</Link>
          <Link to="/contact" onClick={toggleMenu}>Contact</Link>
          <Link to="/admin" onClick={toggleMenu}>Admin</Link>
          <Link to="/about" onClick={toggleMenu}>About</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

